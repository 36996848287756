<template>
  <div class="col">
    <card v-if="auth().family_type == 1">
      <div>
        <span class="text-capitalize">Family Code: </span>
        <span>{{ familyDetails.family_code }}</span>
      </div>
    </card>
    <!--  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="familyMembersCount"
      :DataItems="familyMembers"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Family Members'"
      admin_permission="admin_tasks"
      edit_permission="add_tasks"
    >
      <template #columns>
        <el-table-column
          prop="first_name"
          :label="`${localization('First Name')}`"
        ></el-table-column>
        <el-table-column
          prop="last_name"
          :label="`${localization('Last Name')}`"
        >
        </el-table-column>
        <el-table-column prop="email" :label="`${localization('Email')}`">
        </el-table-column>
        <el-table-column prop="family_type" label="type">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.family_type == 1 ? "Admin" : "Member" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="actions_wrapper td-actions">
              <router-link
                :to="`/families/family-profile/${scope.row.user_id}`"
                v-tooltip.top-center="`${localization('Show Member')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="showAddFamilyMember = true">
          <el-icon class="el-icon-plus"></el-icon>
          Add Family Member
        </div>
      </template>
    </server-table>

    <!-- Start Add Family Member -->
    <el-dialog
      title="Add Family Member "
      width="70%"
      :visible.sync="showAddFamilyMember"
      top="4vh"
      class="add_attributes_dialog_wrapper"
    >
      <el-form
        :model="familyMemberData"
        style="width: 100%"
        ref="addAttributeForm"
        :rules="familyMemberRules"
        label-position="top"
      >
        <div class="row">
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('First Name')}`"
              prop="first_name"
            >
              <el-input
                type="text"
                v-model="familyMemberData.first_name"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('Last Name')}`"
              prop="last_name"
            >
              <el-input
                type="text"
                v-model="familyMemberData.last_name"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('Email')}`"
              prop="email"
              :rules="[
                {
                  required: true,
                  message: 'Please input email address',
                  trigger: 'blur'
                },
                {
                  type: 'email',
                  message: 'Please input correct email address',
                  trigger: ['blur', 'change']
                }
              ]"
            >
              <el-input
                type="email"
                v-model="familyMemberData.email"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('password')}`"
              prop="password"
              class="text-capitalize"
            >
              <el-input
                type="text"
                v-model="familyMemberData.password"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('Age Range')}`"
              prop="age_group_id"
            >
              <el-select
                v-model="familyMemberData.age_group_id"
                class="select-default w-100"
              >
                <el-option
                  v-for="item in ageList"
                  :value="item.value"
                  :label="item.label"
                  :key="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="`${localization('Languages')}`"
              prop="language"
            >
              <el-select
                v-model="familyMemberData.language"
                class="select-default"
                style="width: 100%"
              >
                <el-option
                  v-for="item in languagesList"
                  :value="item.id"
                  :label="item.title"
                  :key="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-3">
            <el-form-item :label="`${localization('City')}`" prop="city">
              <el-input type="text" v-model="familyMemberData.city"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-3">
            <el-form-item :label="`${localization('State')}`" prop="state">
              <el-input type="text" v-model="familyMemberData.state"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-3">
            <el-form-item
              :label="`${localization('Zip Code')}`"
              prop="zip_code"
            >
              <el-input
                type="text"
                v-model="familyMemberData.zip_code"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-3">
            <el-form-item :label="`${localization('Country')}`" prop="country">
              <el-select
                v-model="familyMemberData.country"
                filterable
                class="select-default"
                style="width: 100%"
              >
                <el-option
                  v-for="item in countries"
                  :value="item"
                  :label="item"
                  :key="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="showAddFamilyMember = false"
          >Cancel</el-button
        >
        <el-button
          class="text-center text-capitalize save_button"
          type=""
          @click="addNewMember"
          >Save</el-button
        >
      </span>
    </el-dialog>
    <!-- End Add Family Member -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showAddFamilyMember: false,
      filter: {},
      familyMemberData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        age_group_id: "",
        language: "",
        city: "",
        state: "",
        zip_code: "",
        country: ""
      },
      ageList: [
        {
          value: 1,
          label: "6-12 years old"
        },
        {
          value: 2,
          label: "13 -21 years old"
        },
        {
          value: 3,
          label: "22 or older"
        }
      ],

      familyMemberRules: {
        first_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        password: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        city: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        state: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        zip_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        country: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      languagesList: [],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ]
    };
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("user/handlerFamilyMembers", {
          query: query
        })
        .then(res => {
          this.languagesList = res.languages;

          this.familyMemberData.city = this.auth().city;
          this.familyMemberData.state = this.auth().state;
          this.familyMemberData.zip_code = this.auth().zip_code;
          this.familyMemberData.country = this.auth().country;
        });
    },
    addNewMember() {
      this.$refs["addAttributeForm"].validate(valid => {
        if (valid) {
          this.familyMemberData.confirm_password = this.familyMemberData.password;
          this.$store
            .dispatch("user/handleAddNewFamilyMember", {
              query: this.familyMemberData
            })
            .then(() => {
              this.showAddFamilyMember = false;
              Swal.fire({
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.Refresh();
            });
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    familyMembers() {
      return this.$store.getters["user/getFamilyMembers"];
    },
    familyMembersCount() {
      return this.$store.getters["user/getFamilyMembersCount"];
    },
    familyDetails() {
      return this.$store.getters["user/getFamilyDetails"];
    }
  },
  components: {
    ServerTable
  }
};
</script>

<style lang="scss" scoped></style>
